/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Poppins-Regular;
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("./fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Mulish-Bold;
  src: url(./fonts/Mulish/Mulish-Bold.ttf);
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url(./fonts/Inter/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(./fonts/Inter/Inter-SemiBold.ttf);
}



.container_startpn_table{
  width: 1090px;

}

.container_startpn_table table{
  width: 1090px !important;
   
  text-align: center;
  border-collapse: separate;
  border-spacing:0px 20px ;
  
}

.container_startpn_table thead{
  color: rgb(106, 106, 106);
  font-size:0.9rem;
  font-family: Poppins-Medium;
  height: 68px;
}



.container_startpn_table thead > tr >td {
  padding-left: 2rem;
  padding-right: 2rem;


  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
}

.container_startpn_table thead > tr > td:last-child{
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  border-right: 1px solid #D7D7D7;
}

.container_startpn_table thead > tr > td:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #D7D7D7;
}

.container_startpn_table tbody > tr > td:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #D7D7D7;
}

.container_startpn_table tbody > tr > td:last-child{
  border-top-right-radius: 10px;
  border-end-end-radius: 10px;
  border-right: 1px solid #D7D7D7;
}

.container_startpn_table tbody > tr td{
  cursor: pointer;
  padding: 1rem; /* Espaçamento interno das células */
}

.container_startpn_table .special_td_image{
  position: relative;
}

.container_startpn_table tbody > tr > td > img {
  top:15px;
  left: 20px;
  width: 45px;
  height: 45px;
  border-radius: 100px;
}

.container_startpn_table  tbody > tr > td {
  padding-left: 2rem;
  padding-right: 2rem;
  color: black;
  font-size:0.9rem;
  font-family: "Poppins-Medium";

  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
}


/* CONTAINER DATA */

body {
  height: 100%;
  background-color: white;

}

.container_data {
  width: 1090px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.thow_colum_layout_dashboard {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 229px 1fr;
  grid-template-columns: 229px 1fr;
  height: 100%;
}



/* LANDING */

.textPrimaryLanding {
  font-family: Poppins-Medium;
  color: #FBB12D;
  font-size: 1.7rem;
  margin-bottom: 40px;
}

.image_landing{

  width: 80%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100vh;
}


/* END LANDING */

/* INPUT */

.textPrimaryLabelInput {
  font-family: Poppins-Regular;
  font-size: 1rem;
  color: black;
}

.textPrimaryInput {
  font-family: Poppins-Medium;
  font-size: 1rem;
  padding-left: 0.9rem;
  color: black;
}

.company_sidebar{
  width: 220px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: 1.9rem;
  height: 130px;
}

.textErrorInput {
  bottom: 1px;
  margin-bottom: 1px;
  position: absolute;
  font-family: Poppins-Medium;
  color: red;
}

.container_landing_image{
  margin-bottom: 1.3rem;
}

.container_landing_image > P {
  font-family: Poppins-Medium;
  color: black;
  font-size: 2rem;
}

.container_inputs_landing > p {
  font-family: Poppins-Medium;
  color: #020066;
  font-size: 1.8rem;
}

.container_inputs_landing{
  margin-top: 0.9rem;
}

.container_landing_image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  
  -webkit-box-orient: vertical;
  
  -webkit-box-direction: normal;
  
      -ms-flex-direction: column;
  
          flex-direction: column;
  -webkit-box-align:first baseline;
      -ms-flex-align:first baseline;
          align-items:first baseline;
}

.container_landing_image > img {
  width: 105px;
  -o-object-fit: contain;
     object-fit: contain;
  height: 105px;
}


.colum_portfolio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_portfolio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 38px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.row_item_portfolio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.row_item_portfolio>textarea {
  resize: none;
  border-radius: 5px;
  height: 97px;
  width: 225px;
  margin-left: 1.0rem;
  font-family: Poppins-Medium;
  color: black;
  outline: none;
  padding-top: 9px;
  padding-left: 9px;
  border: 2px solid #D7D7D7;

}

.not_check_option_sidebar_exit{
  position: absolute;
  bottom:35px;
}

.row_modal_schedule > button{
  margin-top: 0.9rem;
}

.row_modal_schedule > .btn_primary_schedule_modal{
  border: 1px solid #020066;
  border-radius: 60px;
  background-color: white;
  width: 144px;
  height: 50px;
  outline: none;
}

.btn_secondary_schedule_modal{
  border: 1px solid #EA0000;
  border-radius: 60px;
  background-color: white;
  font-family: Poppins-Medium;
  font-size: 13px;
  color: #EA0000;
  width: 144px;
  margin-left: 22px;
  height: 50px;
  outline: none;
}

.text_primary_modal_schedule{
  font-family: Poppins-Medium;
  color: #000000;
  font-size: 26px;
  margin-left: 38px;

}

.container_modal_schedule > p{
  font-family: Poppins-Medium;
  color: #000000;
  width: 480px;
  font-size: 17px;
}

.btn_primary_schedule_modal{
  font-family: Poppins-Medium;
  color: #020066;
  font-size: 13px;
}


.container_login_page {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; /* Centraliza horizontalmente */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;     /* Centraliza verticalmente */
  height: 100vh;           /* Certifica-se de que o contêiner ocupa a altura total da viewport */
}

/* INPUT */


/* BUTTON */

.textPrimaryButton {
  font-family: Poppins-Medium;
  color: white;
  font-size: 1rem;
}


/* BUTTON */


/* SIDEBAR */

.sidebar {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 239px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  background-color: white;
  border-right: 1px solid #D7D7D7;
  height: 100vh;
}

.arrow_product_sidebar{
  margin-left: 1rem;
}

.sidebar>div {
  margin-bottom:0rem;
}

.clean_sidebar{
  height: 10px;
}


.sidebar>img {
  width: 270px;
  margin-bottom: 1.7rem;
}


.exit_icon {
  position: absolute;
  bottom: 35px;
  left:30px;
  width: 70px;
  height: 70px;
}

/* SIDEBAR */


/* HEADER DASHBOARD */

.textPrimaryHeaderDashboard {
  font-family: Poppins-SemiBold;
  font-size: 1.87rem;
  color: #020066;
}

.textClientInfoHeader {
  margin-top: 15px;
  font-size: 0.9rem;
  font-family: Poppins-Regular;
  position: relative;
  right: 12px;
}


/* SIDEBAR CHECKED */

.checked {
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #002768;
  width: 205px;
  height: 39px;

  padding: 1.3rem;
  padding-left: 13px;
  border-radius: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}



.checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: white;
  height: 0px;
}

.not_checked {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  width: 205px;
  height: 39px;
  padding: 1.3rem;
  padding-left: 13px;
  border-radius: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.not_checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: white;
  height: 0px;
}

/* SIDEBAR NOT CHECKED */

.not_checked {
  cursor: pointer;

}

.container_vertical_carrousel{
  width: 100%;
  height: 520px;
}

.checked > img{
  width: 23px;
  height: 23px;
}

.not_checked > img {
  width: 23px;
  height: 23px;
}

.not_checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: #787486;
  height: 0px;
}




.textPrimaryuserTable{
  width: 120px;
  white-space: nowrap; /* Evita que o texto quebre em várias linhas */
  font-family: Poppins-Medium;
  font-size: 0.9rem;
  margin-top: 1rem;
  overflow: hidden; /* Oculta o texto que transborda do contêiner */
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis; 
}

.image_tablelist_schedule{
  width: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100px;
  height: 50px;
  position: absolute;
  left: -34%;
  top: 5%;
}

.image_tablelist {
  width: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100px;
  height: 40px;
  position: absolute;
  left: -34%;
  top: 20%;
}

.user_icon {
  position: relative;
  left: -40px;
}


.geeks {

  border-right: hidden;
}


/* INPUT SEARCH*/

.container_input_search {
  position: relative;
  width: 290px;
  height: 45px;
}

.container_input_search>img {
  position: absolute;
  top: 12px;
  left: 15px;
}

.container_input_search>input {
  width: 290px;
  outline: none;
  text-indent: 50px;
  height: 45px;
  font-family: Poppins-Regular;
  color: #7D7D7D;
  font-size: 1rem;

  border-radius: 60px;
  border: 1px solid #D7D7D7;
}

/* FILTER */

.filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.filter>img {
  margin-left: 21px;
  margin-right: 21px;
}


/* FILTER TYPE */

.filter_type {
  width: 240px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.4rem;
  border: 1px solid #D7D7D7;
  border-radius: 100px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: white;
}

.filter_type>button {
  border: none;
}

.check_filter_type {

  width: 107px;
  font-size: 0.9rem;
  height: 35px;
  font-family: Poppins-Medium;
  color: white;
  border-radius: 100px;
  background-color: #026cb6;
}

.not_check_filter_type {
  width: 107px;
  height: 35px;
  font-size: 0.9rem;

  font-family: Poppins-Medium;
  color: white;
  border-radius: 100px;
  color: #020066;
  background-color: white;
}

/* FILTER PROVIDER */

.filter_provider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* FILTER PROVIDER BUTTON */

.filter_provider_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 3rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.filter_provider_button>button {
  border-radius: 50px;
  width: 142px;
  height: 43px;
  border: none;

  font-family: Poppins-Medium;
  color: white;
  font-size: 14px;
  background-color: #020066;

}

.button_modal{
  border-radius: 50px;
  width: 101px;
  height: 33px;
  border: none;

  font-family: Poppins-Medium;
  color: white;
  font-size: 14px;
  background-color: #026cb6;
}

.button_users{
  border-radius: 50px;
  width: 142px;
  height: 43px;
  border: none;

  font-family: Poppins-Medium;
  color: white;
  font-size: 14px;
  background-color: #026cb6;
}

.row_provider {


  display: -webkit-box;


  display: -ms-flexbox;


  display: flex;
  width: 920px;
}

.row_provider>img {
  margin-left: 21px;
  margin-right: 21px;
}

/* MODAL USER */

.modal-dialog-user {
  width: 48vw;
  /* Occupy the 90% of the screen width */
  max-width: 48vw;

}

.modal-dialog-schedule{
  width: 38vw;
  /* Occupy the 90% of the screen width */
  max-width: 48vw;
}

.modal-dialog-category {
  width: 30vw;
  /* Occupy the 90% of the screen width */
  max-width: 30vw;
}

.container_modal_schedule{
  padding: 2.3rem;
}

/* MODAL OPTIONS */

.modal-dialog-options {

  width: 28vw;
  /* Occupy the 90% of the screen width */
  max-width: 48vw;
}

.container_modal_options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


/* MODAL REMOVE USER */

.row_remove_user_modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.row_remove_user_modal>p {
  color: #000000;
  height: 10px;
  margin-left: 22px;

  font-family: Poppins-Medium;
  font-size: 1.125rem;
}

.remove_user {
  border: none;

  background-color: #FFE1E1;
  width: 92px;
  height: 37px;
  border-radius: 50px;
}

.remove_user {
  font-family: Poppins-Medium;
  color: #EA0000;
  font-size: 15px;
}

.textPrimary_modal_remove {
  font-family: Poppins-Regular;
  color: black;
  text-align: center;
  font-size: 18px;
  width: 380px;
  margin: 0 auto;
  margin-bottom: 40px;
}


.container_modal_options>button {


  width: 100%;
  height: 65px;
  border-top: 1px solid #D7D7D7;

  border-bottom: 1px solid #D7D7D7;
  background-color: white;
}

.btn_primary_modal_option {
  font-family: Poppins-Medium;
  border: none;
  border-top: 1px solid #D7D7D7;
  color: black;
  font-size: 18px;
}

.btn_secondary_modal_option {
  border: none;
  font-family: Poppins-Medium;
  color: #EA0000;
  font-size: 18px;
  margin-bottom: 40px;
}


.header_modal_filter > button{
  width: 130px;
  height: 32px;
  font-size: 0.87rem;
  background-color: white;
  color: #5D5D5D;
  border: 1px solid #D7D7D7;
  border-radius: 50px;
  font-family: Poppins-Medium;

}

.container_filter_modal{
  position: relative;
  margin-right: 1rem;
  margin-left: 1rem;
}

.container_input_modal_filter{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.header_modal_filter{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 2rem;
  border-bottom: 1px solid #D7D7D7;
  height: 50px;
  padding-right: 2rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.header_modal_filter > p {
  color: #000000;
  height: 5px;
  font-family: Poppins-Medium;
  font-size: 1.12rem;
}

.modal_filter{
  z-index: 9999;
  position: absolute;
  width: 375px;
  z-index: 50px;
  height: 347px;
  border-radius: 20px;
  background-color: white;
}
.textModalTableListUserEditPrimary {
  font-family: Poppins-Medium;
  color: #030303;
  font-size: 1.12rem;
}

.modal-filter-table-list-user {

  width: 43vw;   /* Occupy the 90% of the screen width */
  max-width: 90vw;

}

.textPrimaryLabelInput {

  font-family: Poppins-regular;
  color: black;
  font-weight: 400;
  font-size: 0.875rem;

}

.modal-filter-table-list-user-edit {

  width: 24.414vw;
  max-width: 24.414vw;
  border-radius: 20px;
}


.textModalTableListUserEditSecondary {
  font-family: Poppins-Medium;
  color: #EA0000;
  font-weight: 500;
  font-size: 1.12rem;
}

.textBtnRemove {

  font-family: Poppins-Medium;
  color: #EA0000;
  font-size: 0.93rem;
}

.textConfirmationRemove {
  font-family: Poppins-regular;
  font-size: 1.12rem;
  text-align: center;
  margin-top: 1.37rem;
}

.errorMessage {

  font-family: Poppins-regular;
  color: red;
  font-size: 0.9rem;
  position: absolute;
  top: 84px;
  left: 2px;

}

.textPrimaryModalFilterManager {
  position: relative;
  
  left: 15px;
  font-family: Poppins-Medium;
  font-size: 1.125rem;
  margin-left: 18px;
}
.textBtnFilterManagerModal {
  font-family: Poppins-Medium;
  font-size: 0.938rem;
  color: white;

}

.modal-filter-table-list-user {

  width: 43vw;   /* Occupy the 90% of the screen width */
  max-width: 90vw;

}


.textNameTableListUser {

  font-family: Poppins-Medium;
  color: #6A6A6A;
  font-size: 0.87rem;
  text-align: center;
  width: 220px;
  position: relative;
  top: -6px;
}

.container_header_modal_user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}



.container_modal_user_banner{
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container_modal_user {
  max-width: 720px;
  margin: 0 auto;
}



.btn_confirm_schedule_mobile{
  width: 80%;
  border: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 49px;
  border-radius: 50px;
  font-family: Poppins-Medium;
  color: white;
  background-color: #026cb6;
}

.container_header_modal_user>button {
  width: 105px;
  border: none;
  height: 39px;
  border-radius: 50px;
  font-family: Poppins-Medium;
  color: white;
  background-color: #002768;
}


.container_header_modal_user>div {
  width: 175px;
  height: 30px;
}

.container_header_modal_user>p {
  color: #000000;
  height: 15px;
  font-family: Poppins-Medium;
  font-size: 1.125rem;

}

.container_header_modal_user>div p {
  color: #000000;
  height: 15px;
  font-family: Poppins-Medium;
  font-size: 1.125rem;

}



.container_image_modal_user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_image_modal_user>img {
  width: 89px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100px;
  height: 89px;
}


.container_local_services>label {
  font-family: Poppins-Regular;
  color: black;
  font-size: 14px;
  margin-bottom: 14px;

}

.container_image_modal_user>p {
  font-size: 1.31rem;
  margin-top: 0.62rem;
  font-family: Poppins-Medium;
  color: black;

}

.row_avaliation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.row_avaliation>p {
  height: 0px;
}

.text_avaliation_modal {
  font-family: Poppins-Medium;
  color: black;
  font-size: 0.97rem;
  margin-right: 0.3rem;
}

.text_number_avaliations_modal {
  font-family: Poppins-Regular;
  color: black;
  font-size: 0.97rem;
  margin-left: 0.3rem;
}


/* INPUT FILE */
.text_image_file {
  position: absolute;
  font-family: Poppins-Regular;
  color: black;
  top: 46px;
  left: 57px;
  width: 40px;
}

.image_file {

  position: absolute;
  top: 10px;
  left: 8px;
  width: 40px;
}

.box_input_modal > p {
  position: absolute;
  top: 10px;
  left: 58px;

}

.box_input_modal{
  cursor: pointer;
  position: relative;
  font-family: Poppins-Regular;
  color: black;
  padding-left: 0.9rem;
  width: 304px;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #D7D7D7;
}

input[type="file"] {
  display: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

input[type="file"]::before {
  content: 'Procurar...';
  /* Texto customizado */
  display: inline-block;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

input[type="file"]::file-selector-button {
  visibility: hidden;
}

.container_carrousel_data > p {
  color: black;
  font-family: Poppins-Medium;
  font-weight: 500;
  font-style: normal;
  font-size: 1.1rem;
}

/* Estilização da imagem dentro do SwiperSlide */


.swiper-slide img {
  width: 400px;
  height: auto;
  object-fit: contain;
}

#input_update_carrousel{
  display: none;
}
.carrousel_card{
  margin: 0 auto;
  width: 400px;
}

.carrousel_card > input[type="file"]{
  display: none;
}

.header_carrousel_card{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row_carrousel_input{
  margin-top: 1.3rem;
  display: flex;
  justify-content: center;
}

.carrousel_card > button{
  border: none;
  font-size: 2rem;
  background-color: transparent;
}
/* INPUT MODAL */

.row_input_modal_user_banner{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}



.row_input_modal_user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0px;
  margin-top: 35px;
  margin-bottom: 0px;
  padding: 38px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_input_modal_variation{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
}

.container_input_modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}

.container_input_modal_text_area{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top:40px;
  margin-left: 38px;
}

.container_input_local_service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

}

.container_input_local_service>label {
  font-family: Poppins-Regular;
  color: black;
  font-size: 14px;
  margin-bottom: 14px;
}

.container_input_modal>label {
  font-family: Poppins-Regular;
  color: black;
  font-size: 14px;
  margin-bottom: 14px;

}

.container_input_modal_text_area > label{
  font-family: Poppins-Regular;
  color: black;
  font-size: 14px;
  margin-bottom: 14px;

}

.container_input_modal_variation>label {
  font-family: Poppins-Regular;
  color: black;
  font-size: 14px;
  margin-bottom: 7px;

}

.container_bottom_select_services {
  position: relative;
}

.container_bottom_select_services>label {
  font-family: Poppins-Regular;
  color: black;
  font-size: 14px;
  margin-bottom: 14px;
}

.container_input_service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}


.preview_service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.preview_service>img {
  width: 40px;
  border-radius: 100px;
  height: 40px;
}


.flex_bottom_input_service>div {
  margin-left: -120px;
}

.bottom_input_service {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 304px;
  height: 50px;

}

.bottom_input_service>img {
  width: 36px;

  -o-object-fit: cover;

     object-fit: cover;
  border-radius: 100px;
  height: 36px;
}

.bottom_input_service>p {
  margin-top: 0.9rem;
  margin-left: 0.9rem;
}


.bottom_local_services {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  margin-top: 1rem;
  width: 404px;
  padding: 30px;
  height: 40px;
  padding-left: 0rem;
  border-radius: 10px;
  margin-bottom: 0rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

}

.container_local_services {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

      -ms-flex-direction: column;

          flex-direction: column;
}

.container_local_service_data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 150px;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 0rem;
}



.preview_bar_code{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 2.9rem;
}

.preview_bar_code > p {
  font-family: Poppins-Regular;

}

.box_local_images{
  -webkit-transition: all ease-in .2s;
  -o-transition: all ease-in .2s;
  transition: all ease-in .2s;
  position: relative;
  border: 0.2px solid #FBB12D;
  border-radius: 100%;
  min-width: 98px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 98px;
  margin-top: 2rem;
  margin-left: 2rem;
}
.box_local_images > img {
  width:86px;
  height: 86px;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.box_local_images:hover{
  -o-object-fit: contain;
     object-fit: contain;  
  -webkit-transform: translate(0%,-5%);  
      -ms-transform: translate(0%,-5%);  
          transform: translate(0%,-5%);
}

.box_local_images:hover > img{
  cursor: pointer;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow:  0 0 5px rgba(0, 0, 0, 0.176);
          box-shadow:  0 0 5px rgba(0, 0, 0, 0.176);

}



.box_local_images > .remove_item_local_image{
  cursor: pointer;
  position: absolute;
  font-family: Poppins-Regular;
  left: 0;
  top: -15px;
}



.local_service_data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 120px;
  height: 120px;
  margin-top: 5rem;
  margin-bottom: 0rem;
}

.local_service_data>img {
  width: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  height: 50px;
}

.local_service_data>svg {
  position: relative;
  top: 12px;
  left: -1px;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background-color: white;
}

.local_service_data>p {
  font-size: 0.9rem;

}

.bottom_local_services>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1rem;
  height: 30px;
  margin-bottom: 0.9rem;
}

.bottom_local_services>div img {
  width: 26px;

  -o-object-fit: cover;

     object-fit: cover;
  border-radius: 100px;
  height: 26px;
}

.bottom_local_services>div p {
  font-size: 1rem;
  width: 100px;
}

.bottom_select_services {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 3px;
  width: 304px;
  min-height: 60px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 0.9rem;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.326);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.326);
  border-radius: 10px;
  background-color: white;
  z-index: 999px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.bottom_select_services>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bottom_select_services>div>img {
  width: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100px;
  height: 40px;
}

.bottom_select_services>div>p {
  font-family: Poppins-Regular;
  height: 0px;
  width: 120px;
  margin-top: 0rem;
  margin-left: 0.5rem;
  font-size: 0.9rem;
}

.input_service {
  position: relative;
  font-family: Poppins-Regular;
  color: black;
  overflow-x: auto;
  z-index: 9999px;
  padding-top: 0.2rem;
  padding-left: 0.6rem;
  width: 304px;

  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #D7D7D7;
}

.input_modal_variation{
  font-family: Poppins-Regular;
  color: black;
  padding-left: 0.9rem;
  width: 224px;
  outline: none;
  height: 39px;
  border-radius: 10px;
  border: 1px solid #D7D7D7;
}

.input_modal {
  font-family: Poppins-Regular;
  color: black;
  padding-left: 0.9rem;
  width: 304px;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #D7D7D7;
}

.input_modal_description{
  resize: none;
  font-family: Poppins-Regular;
  color: black;
  padding-left: 0.9rem;
  padding: 0.9rem;
  width: 90%;
  outline: none;
  height: 320px;
  border-radius: 10px;
  border: 1px solid #D7D7D7;
}

/* TEXT TYPE FORM */

.text_type_form {
  margin-top: 40px;
  height: 15px;
  font-family: Poppins-Medium;
  color: black;
  font-size: 1.12rem;

  margin-left: 38px;
}

/* SELECT MODAL USER */

.select_modal_user {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding-left: 0.9rem;
  background: white;
  font-family: Poppins-Regular;
  color: black;
  width: 304px;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #D7D7D7;
}

option{
  font-family: Poppins-Regular;

}

.container_select_modal {
  position: relative;
  width: 304px;
  margin-top: 15px;
}

.arrow_select {

  bottom: 14px;
  right: 15px;
  position: absolute;
}

.arrow_select_invert {
  bottom: 14px;
  right: 15px;
  position: absolute;
}

.container_select_modal>label {
  font-family: Poppins-Regular;
  color: black;
  font-size: 14px;
  margin-bottom: 14px;
}



/* CHECK TABLE LIST */

.check_tablelist {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 2px solid #989898;
  background-color: white;
}


.check_tablelist:checked {
  background-color: #989898;
}

/* CARD AVALIATION */

.card_avaliation {
  padding: 10px;
  width: 190px;
  min-height: 186px;
  margin-right: 20px;
  border-radius: 8px;
  background-color: #F7F9FB;
}

.header_card_avaliation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

}

.header_card_avaliation>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.header_card_avaliation>div>.textPrimary_header_card_avaliation {
  font-family: Poppins-Medium;
  color: black;
  position: relative;
  top: 5px;
  font-size: 0.9rem;
  margin-left: 8px;
}

.textSecondary_header_card_avaliation {
  font-family: Poppins-Regular;
  width: 150px;
  /* Impede que o texto quebre em várias linhas */
  overflow: hidden;
  /* Oculta qualquer conteúdo que ultrapasse a largura do contêiner */
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  /* Adiciona reticências (...) para indicar que o texto foi cortado */
  color: black;
  position: relative;
  top: 10px;

  font-size: 0.9rem;
  margin-left: 0px;
}

.textTableList{
  width: 150px;
  white-space: nowrap;
  /* Impede que o texto quebre em várias linhas */
  overflow: hidden;
  /* Oculta qualquer conteúdo que ultrapasse a largura do contêiner */
     text-overflow: ellipsis;
}


.header_card_avaliation>div>img {
  width: 44px;
  -o-object-fit: cover;
     object-fit: cover;
  height: 44px;
  border-radius: 100px;
}

/* SCROLL AVALIATIONS */

.scroll_avaliations {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  margin-left: 38px;
}

/* CARD FINANCIAL */

.row_financial {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 37px;
}

.cardFinancial {
  width: 221px;
  height: 171px;
  padding-top: 14px;
  padding-left: 27px;
  border-radius: 11px;
  background-color: white;
}

.cardFinancial>.textPrimary_financial {

  font-size: 16px;
  font-family: Mulish-Bold;
  color: #A1A0BD;

}

.cardFinancial>.textSecondary_financial {
  font-size: 29px;
  font-family: Inter-ExtraBold;
  color: #000000;
}


.grafic_financial_loss {
  margin-top: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 87px;
  height: 32px;
  border-radius: 19px;
  background-color: #F5DCDC;
}

.grafic_financial_loss>p {
  height: 10px;
  margin-left: 4px;
  font-family: Inter-SemiBold;
  font-size: 15px;
  color: #D14F4F;
}

.grafic_financial {
  margin-top: 28px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  padding-left: 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 87px;
  height: 32px;
  border-radius: 19px;
  background-color: #DCF5E8;
}

.grafic_financial>p {
  height: 10px;
  margin-left: 4px;
  font-family: Inter-SemiBold;
  font-size: 15px;
  color: #4FD18B;
}

.receive_button_financial {
  border: 1px solid #4C49ED;
  width: 87px;
  height: 32px;
  border-radius: 19px;
}


.receive_button_card_financial {
  width: 87px;
  height: 32px;
  border-radius: 19px;
  font-family: Inter-SemiBold;
  font-size: 12px;
  color: #4C49ED;
  background-color: white;
  border: 1px solid #4C49ED;
}

/* NOT FOUND CONTAINER */

.not_found_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.not_found_container>p {
  font-family: Inter-SemiBold;
  font-size: 19px;
}

.not_found_container>img {
  width: 420px;
  height: 420px;
}

.container_input_style {
  width: 418px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;

}

.input_style {
  position: relative;
  width: 318px;
  height: 50px;
  font-family: Poppins-Medium;
  font-size: 1rem;
  padding-left: 0.9rem;
  color: black;
  outline: none;
  margin-bottom: 1.9rem;
  border-radius: 10px;
  border: 1px solid #D7D7D7
}


.input_style_landing {
  position: relative;
  width: 420;
  height: 50px;
  font-family: Poppins-Medium;
  font-size: 1rem;
  padding-left: 0.9rem;
  color: black;
  outline: none;
  margin-bottom: 1.9rem;
  border-radius: 10px;
  border: 1px solid #D7D7D7
}

.button_mobile {
  display: none;
}

.container_secondary {

  margin-top: 1.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_mobile {
  display: none;
}

.container_input_mobile {
  display: none;
}


.mobile_service {
  display: none;
}

.container_animation_data{
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  position: absolute;
  z-index: 99999;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.404);
  height: 100vh;
  width:100%;
}

.container_animation_data > div{
  margin-left: 150px;
}

.local_services_mobile{
  display: none;
}

.image_add_newIcon{
  width: 70px;
  height: 70px;
}


.box_card_variation{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position:absolute;
  margin-top: 0.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 6px;
  top: -25px;
  z-index: 999999;
  background-color: white;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid #39383873;
  width: 55px;
  height: 55px;

}

.input_file_variation > img {
  width: 40px;
  height: 40px;
}

/* CARD VARIATIONS */

.textPrimary_variation{
  font-size: 1.1rem;
  font-family: Poppins-Medium;
  margin-left: 38px;
}

.card_variation{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 1rem;
  min-height: 148px;
  margin-bottom: 0.1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 600px;
  border: 1px solid #D7D7D7;
  border-radius: 7px;
}

.container_input_file_modal{
  font-family: Poppins-Regular;
  color: black;
  padding-left: 0.9rem;
  width: 224px;
  outline: none;
  height: 39px;
  border-radius: 10px;
  border: 1px solid #D7D7D7;
}

.container_images_card_variation{
  display: flex;
  overflow-x: auto;
  margin-top: 1.9rem;
  width: 450px;
}

.container_images_card_variation > div{
  position: relative;
}

.container_images_card_variation > div > p{
  cursor: pointer;
  position: absolute;
  top: 2px;
  
  right: 15px;

}

.container_images_card_variation > div > img {
  width: 100px;
  border: 0.2px solid #FBB12D;
  border-radius: 100%;
  margin-right: 2rem;
  height: 100px;
}

.container_input_file_modal > label > img {
  width: 30px;
  position: relative;
  top: 5px;
  height: 30px;
}

.container_primary_card_variation{
  display: -webkit-box;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 520px;
  margin: 0 auto;

  margin-top: 1rem;
  margin-bottom: 1rem;
  
}


.container_card_variation{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

      -ms-flex-direction: column;

          flex-direction: column;
  margin-top: 0.9rem;
  padding-left: 38px;
}

.remove_icon_stock{
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 0.9rem;
}

/* RESPONSIVE DESIGN */

::-webkit-scrollbar-track {
  background: #d8d8d8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.33);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8.5px;
  height: 15px;
  background: #d8d8d8;
  border-radius: 50px;
}